.interviewHeaderRoot {
    position: relative;
    min-width: 335px;
    width: 100%;
    max-width: 500px;
    height: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    overflow: hidden;

    .transition-header-group-root {
        width: 100%;
        height: 100%;
        z-index: 0;

        .background-image {
            position: absolute;
            top: 0;
            left: -10%;
            width: 120%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            z-index: 0;
            &.right-aligned {
                object-position: center right;
            }
            &.left-aligned {
                object-position: center left;
            }
        }

        .header-animation-enter {
            transform: translateX(10%);
            z-index: 1;
            opacity: 0;
        }

        .header-animation-enter.header-animation-enter-active {
            transform: translateX(0);
            transition: 800ms;
            transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
            opacity: 1;
        }

        .header-animation-exit {
            transform: translateX(0);
            z-index: 2;
        }

        .header-animation-exit.header-animation-exit-active {
            transform: translateX(-10%);
            transition: 800ms;
            transition-timing-function: ease-out;
            opacity: 0;
            z-index: 2;
        }
    }

    .header-container {
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        z-index: 1;

        .recommendationsContainer {
            margin-top: 20px;
            margin-left: 6px;
            margin-right: -10px; // to make sure they wrap better on iPhone 6
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .recommendationItemContainer {
                margin-bottom: 8px;
                display: flex;
            }
        }
        .headerContainers {
            display: flex;
            float: 1;
            flex-direction: row;
            justify-content: flex-end;

            .headerTitle {
                margin-left: 20px;
                font-size: 21px;
                color: #005aa0;
                margin-top: 27px;
                flex-grow: 1;
                display: flex;

                .headerTitleText {
                    font-family: 'LFRubrik';
                    background-color: white;
                    flex-grow: 0;
                    padding: 4px 14px 4px 14px;
                    font-size: 22px;
                    .headerTitleAccentText {
                        color: #e60710;
                        font-family: 'LFRubrik';
                    }
                }
            }

            .headerAnswers {
                display: flex;
                flex: 1;
                margin-top: 24px;
                margin-left: 20px;
                margin-right: 20px;

                div {
                    color: #005aa0;
                }
            }

            .answeredQuestionsCounter {
                margin-right: 20px;
                font-size: 18px;
                color: #005aa0;
            }

            .multiRow {
                flex-wrap: wrap;
            }
        }

        .progressBarContainer {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 21px;
            position: absolute;
            width: 100%;
            box-sizing: border-box;
        }
    }

    .bigTitle {
        margin-left: 26px;
        margin-right: 26px;
        font-size: 28px;
        color: #005aa0;
        margin-top: 40px;
        flex-grow: 1;
        display: flex;
        justify-content: center;

        // Small screen - make title text smaller
        @media screen and (max-width: 350px) {
            font-size: 20px;
        }

        .bigTitleText {
            font-family: 'LFRubrik';
            background-color: white;
            flex-grow: 0;
            padding: 9px 14px 9px 14px;
            .bigTitleAccentText {
                color: #e60710;
                font-family: 'LFRubrik';
            }
        }
    }
    // When the title is the only information to show in the header
    .header-container-alone {
        @extend .header-container;
        position: static;
        margin-top: 0px;
        bottom: 100px;
        margin-bottom: 40px;
    }


  .fadeIn {
    animation: fadeIn 0.5s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

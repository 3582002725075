.rootContainer {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 90, 160, 0.2);
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contentContainer {
    display: flex;
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.dashboard {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.backgroundImageContainer {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    background-image: url('./../assets/images/wave.svg');
    background-size: cover;
}

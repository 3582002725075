.answerItemRoot {
    display: flex;

    .answerContainer {
        cursor: pointer;
        width: 100%;
        height: 45px;

        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 0.5px #d9dadc;
        background-color: #ffffff;

        box-sizing: border-box;
        display: flex;
        justify-content: center;

        .answerText {
            cursor: default;
            font-size: 16px;
            font-weight: 800;
            color: #005aa0;
            align-self: center;
        }
        /*
        &:active {
            background-color: #005aa0;
            .answerText {
                color: #ffffff;
            }
        }*/
    }

    .answerContainerMultiSelected {
        @extend .answerContainer;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #005aa033;
    }

    .answerContainerSubmit {
        @extend .answerContainer;

        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #005aa0;
        .answerText {
            color: #ffffff;
        }
    }

    .answerContainerSubmitNone {
        @extend .answerContainer;

        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 0.5px #d9dadc;
        background-color: rgba(227, 4, 18, 0.2);
    }

    .answerContainerNarrow {
        @extend .answerContainer;
        cursor: auto;
        .answerText {
            font-size: 14px;
        }
        transition-duration: 500ms;
        transition-delay: 300ms;
        height: 35px;
        padding: 0 10px;
        min-width: 80px;
        white-space: nowrap;
        margin-right: 15px;

        &:active {
            background-color: #ffffff;
        }
    }

    .answerContainerTicks {
        @extend .answerContainerNarrow;

        transition-duration: 750ms;
        transition-delay: 0ms;

        padding: 0 10px;
        min-width: 60px;
        height: 35px;
        padding: 0 15px;
        white-space: nowrap;
        margin-right: 10px;
    }
}

.transition-question-group-root {
    position: relative;
    height: 100%;
    width: 100%;

    .questionRoot {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        min-width: 335px;
        width: 100%;
        max-width: 500px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        padding-top: 20px;

        padding-bottom: 20px;

        .imageContainer {
            height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-bottom: 23px;

            .image {
                width: 100%;
                align-self: center;
            }
        }

        .titleContainer {
            height: 61px;
            margin-bottom: 26px;
            margin-left: 21px;
            margin-right: 21px;

            color: #005aa0;

            text-align: center;

            .firstLineOfText {
                font-size: 25px;
                font-family: 'LFRubrik';
                flex-grow: 0;
            }
            .secondLineOfText {
                font-family: IntroCond;
                font-size: 16px;
                flex-grow: 0;
            }
        }

        .answersContainer {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 37%);
            grid-column-gap: 5%;
            grid-row-gap: 20px;
            justify-content: center;
            padding-bottom: 20px;
        }
        .manyItemAnswersContainer {
            @extend .answersContainer;
            grid-template-columns: repeat(2, 37%);
            grid-row-gap: 12px;
        }
        .singleItemAnswersContainer {
            @extend .answersContainer;
            grid-template-columns: repeat(1, 37%);
        }
    }
}

.question-animation-enter {
    transform: translateX(100%);
    z-index: 1;
}

.question-animation-enter.question-animation-enter-active {
    transform: translateX(0);
    transition: 800ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.question-animation-exit {
    transform: translateX(0);
}

.question-animation-exit.question-animation-exit-active {
    transform: translateX(-100%);
    transition: 800ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

@import 'fonts';

body {
    margin: 0px;
    padding: 0px;
}

* {
    font-size: 100%;
    font-family: 'IntroCond';
    margin: 0px;
}

#icon:hover {
    fill: red;
}

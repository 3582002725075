@font-face {
    font-family: 'IntroCond';
    src: url('./assets/fonts/introcond-bold.woff2') format('woff2'), url('./assets/fonts/introcond-bold.woff') format('woff');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'IntroCond';
    src: url('./assets/fonts/introcond-regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/introcond-regular.woff2') format('woff2'),
        url('./assets/fonts/introcond-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LFRubrik';
    src: url('./assets/fonts/LFRubrik-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/LFRubrik-Regular.woff2') format('woff2'), url('./assets/fonts/LFRubrik-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

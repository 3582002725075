.recommendationItemRoot {

    a, a:visited {
        color: #005aa0;
        text-decoration: none;
    }

    &.itemLink {
        text-decoration: none;
        cursor: pointer;
    }
    z-index: 1;
    position: relative;

    .recommendationWithButtonContainer {
        padding: 20px 0px;
        margin-top: 20px;

        border: solid 1px #f3f3f3;
        background-color: #ffffff;
        box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.15);

        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .recommendationTextAndImageContainer {
            padding-top: 15px;
            padding-left: 15px;
            // padding: 15px;
            // margin-top: 20px;

            // border: solid 1px #f3f3f3;
            // background-color: #ffffff;
            // box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.15);

            display: flex;
            align-items: flex-start;
            flex-direction: row;

            .recommendationIcon {
                align-self: flex-start;
                min-width: 45px;
                min-height: 45px;
                flex-grow: 0;
                margin-right: 7px;
            }
            .recommendationTextWrapper {
                display: flex;
                padding: 5px;
                flex-direction: column;
                z-index: 1;

                .recommendationText {
                    font-size: 18px;
                    font-family: LFRubrik;
                    text-align: left;
                    color: #005aa0;
                    align-self: left;
                    padding-bottom: 20px;
                }
                .recommendationSubHeader {
                    font-family: LFRubrik;;
                    color: #005aa0;
                    padding-top: 20px;
                }
                .officeAndPhoneNumberWrapper {
                    margin-bottom: 4px;
                }
                .firstColumn {
                    padding: 0px;
                }
                .secondColumn {
                    padding: 0px 0px 0px 24px;
                }
                .infoText {
                    font-size: 15px;
                    font-family: IntroCond;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;

                    color: #000;
                    align-self: left;
                    white-space: pre-wrap;
                }
                .linkWrapper {
                    margin-bottom: 12px;
                }
            }
        }

        a.phoneNumber {
            font-size:18px;
        }

        form, button, input:not([type="checkbox"]) {
            width: 100%;
            outline: none;
            border: none;
        }

        form.submit_email {
            &.disabled {
                opacity: 0.3;
            }
        }
        .submit_overlay {
            width: calc(100% - 20px);
            height: 0px;
            z-index: 1;

            .loader {
                margin: auto;
                margin-top: 20px;
                border: 7px solid #EAF0F6;
                border-radius: 50%;
                border-top-color:  #005aa0;
                border-bottom-color: #005aa0;
                width: 50px;
                height: 50px;
                opacity: 0.9;
                animation: spinner 1.75s linear infinite;
              }
              @keyframes spinner {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }

            .done {
                margin: auto;
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                text-align: center;
                .checkmark {
                    flex-shrink: 0;
                    margin: auto;
                    border: 7px solid #1b961b;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                p {
                    margin-top: 20px;
                    line-height: 1.65;
                    b {
                        font-size: 20px;
                    }
                }
            }
        }
        .acceptLabel {
            display: inline-block;
            padding-top: 20px;
        }
        .infoButton, .submitButton {
            background-color: #005aa0;
            height: 45px;
            margin-left: 20px;
            margin-top: 20px;
            margin-right: 20px;
            width: calc(100% - 40px);
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            transition: background-color 0.2s;

            &:disabled {
                background-color: #82aecf;
            }
            &:not([disabled]){
                cursor: pointer;
                &:hover {
                    background-color: #003366;
                }
            }
        }
        .submitButton{
            margin-left: 0px;
            width: calc(100% - 20px);
        }

        .infoButton .infoButtonText,
        .submitButton {
            color: #fff;
            font-weight: bold;
            font-size: 18px;
            font-family: IntroCond;
        }
        .errorMessage {
            color: red;
            font-size: 14px;
            font-family: IntroCond;
            margin-top: 10px;
            margin-right: 30px;
            a {
                color: red;
                text-decoration: underline;
            }
        }

        .pulWrapper {
            font-size: 12px;
            margin-left: 5px;
            margin-right: 30px;
            margin-top: 25px;
            p {
                margin-bottom: 5px;
            }
            .pulWrapperButton {
                color: #005aa0;
                cursor: pointer;
                padding-bottom: 1rem;
            }
        }
        input:not([type="checkbox"]) {
            // background-color: #fff;
            border:2px solid #d9d9d9;
            margin: 20px 0px 0px;
            padding: 10px 10px;
            width: calc(100% - 20px);
            box-sizing: border-box; /* CSS 3 rec */
            -moz-box-sizing: border-box; /* Firefox 2 */
            -ms-box-sizing: border-box; /* Internet Explorer 8 */
            -webkit-box-sizing: border-box; /* Safari 3 */
            -khtml-box-sizing: border-box; /* Konqueror */

            transition: border-color 0.3s;
            &:focus, &:active {
                border-color: #005aa0;
                outline: none;
            }
        }
    }

    .recommendationContainer {
        padding: 15px;
        margin-top: 20px;

        border: solid 1px #f3f3f3;
        background-color: #ffffff;
        box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.15);

        display: flex;
        align-items: flex-start;
        flex-direction: row;

        &.itemLink {
            background-color: #005aa0;
        }

        .recommendationIcon {
            align-self: flex-start;
            min-width: 45px;
            min-height: 45px;
            flex-grow: 0;
            margin-right: 7px;
        }
        .recommendationTextWrapper {
            display: flex;
            padding: 5px;
            flex-direction: column;
            z-index: 1;

            .recommendationText {
                font-size: 18px;
                font-family: LFRubrik;
                text-align: left;
                color: #005aa0;
                align-self: left;
                padding-bottom: 10px;

                &.itemLink {
                    color: #fff;
                }
            }
            .infoText {
                font-size: 15px;
                font-family: IntroCond;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;

                color: #000;
                align-self: left;
                white-space: pre-wrap;

                &.itemLink {
                    color: #fff;
                }

                a:visited {
                    color: #005aa0;
                    font-family: IntroCond;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-decoration: none;
                }
                a:link {
                    color: #005aa0;
                    font-family: IntroCond;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-decoration: none;
                }

                .buttonLink {
                    color: #005aa0;
                    font-family: IntroCond;
                    cursor: pointer;
                }
            }
        }

        .infoButton {
            background-color: #005aa0;
            height: 45px;
            margin-top: 20px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .infoButtonText {
                color: #fff;
                font-weight: bold;
                font-size: 18px;
                font-family: IntroCond;
            }

            transition: background-color 0.2s;
            &:hover {
                background-color: #003366;
            }
        }
    }
}

.contentSceneRoot {
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 500px;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow-x: hidden;

    box-shadow: 0 0 250px -12px rgba(0, 0, 0, 0.25);
    background-color: white;

    .recommendationsContainer {
        margin-top: -50px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        height: 100%;

        .footerBelowRecommendations {
            height: 40px;
        }
    }

    .pageInfoText {
        margin-left: 13%;
        margin-right: 13%;
        font-family: IntroCond;
        font-size: 21px;
        text-align: center;

        color: #005aa0;
    }

    .verticalStretchedStack {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        flex-grow: 1; // Fill out vertical space in parent container
    }

    .nextButton {
        display: flex;
        height: 45px;
        padding-left: 35px;
        padding-right: 35px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #005aa0;
        cursor: pointer;

        .nextButtonText {
            font-size: 16px;
            font-family: IntroCond;
            font-weight: bold;
            text-align: center;
            color: #ffffff;
            align-self: center;
        }
    }
}

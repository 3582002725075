.progressBarRoot {
    display: flex;
    align-items: center;

    .valueBarContainer {
        height: 4px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
        background-color: #f49ca1;
        width: 100%;

        .valueBar {
            height: 4px;
            background-color: #e30412;
            transition-duration: 500ms;
            transition-timing-function: cubic-bezier(0.36, 0.16, 0.37, 0.92);
        }
    }
}
